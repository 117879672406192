<template>
      <div class="activity is-pulled-right">
        <aside>
          <div class="card">
            <header class="card-header">
              <label class="card-header-title has-text-black-bis">
                Documents ({{files.length}})
              </label>
              <div>
                <mf-add-file
                  :order="orderCard"
                  :projectId="projectId"
                  :showForm="showForm"
                  :defaultPrivate="isDefPrivate"
                  @docs-added="docsAdded"
                  :isDisabled="isDisabled"
                >
              </mf-add-file>
              </div>
              <div class="is-divider-vertical"></div>
              <span
                class="card-header-icon"
                aria-label="more options"
                @click="closeDocsSlider"
              >
                <i class="icon-close"></i>
              </span>
            </header>
            <div class="card-content">
              <div class="is-size-3 has-text-black-bis has-text-weight-bold mb-2 line-height">
                {{ isCL ? taskName: itemName }}
              </div>
              <p class="control has-icons-left has-icons-right">
                <input type="text" v-model="searchText" class="input" placeholder="Search">
                <span class="icon is-left">
                  <i class="icon-search"></i>
                </span>
              </p>
              <file-list
                :files="filteredFileList"
                :order="rowData"
                :closable="isClosable"
                :projectId="projectId"
                @close="deleteFile"
                :card="card"
                :isDisabled="isDisabled"
              >
              </file-list>
            </div>
          </div>
        </aside>
      </div>
</template>

<script>
import {
  defineComponent, reactive, toRefs, computed,
} from 'vue';
import {
  get, some, find, filter, findIndex, cloneDeep, pick, isEmpty,
} from 'lodash';
import { CardDirtyBus } from '@/utils/CardDirtyBus';
import FileList from '@/components/card-edit/FileList.vue';
import Upload from '@/models/Upload';
import MfAddFile from '@/components/MfAddFile.vue';
import { DialogProgrammatic } from '@/components/Dialog';
import { useStore } from 'vuex';
import { BaseOrder } from '@/models/BaseOrder';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'ItemDocumentSlider',
  props: {
    isActive: Boolean,
    rowData: Object,
    rowField: Object,
    value: Array,
    card: Object,
    isDisabled: Boolean,
    checkListOrder: Object,
  },
  components: {
    'file-list': FileList,
    'mf-add-file': MfAddFile,
  },
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const data = reactive({
      searchText: '',
      isBO: props.rowData.instanceOf === 'BaseOrder',
      isSL: props.rowData.instanceOf === 'ShippingLabel',
      isCL: props.rowData.instanceOf === 'CheckList',
      t: get(props.rowData, 'order.__t', props.rowData?.__t),
      customStage: get(props.rowData, '_customStage', props.rowData?.order?.__t),
      isManager: get(props.rowData, 'isManager'),
      itemName: get(props.rowData, 'name'),
      taskName: get(props.rowData, 'text'),
      orderCard: get(props.rowData, 'order', props.checkListOrder) || props.card,
    });
    const projectId = computed(() => {
      if (data.isBO) {
        return get(props, 'card.project._id', '');
      }
      if (data.isSL) {
        return get(props.rowData, 'delivery.currentProject._id', '');
      }
      return get(props.rowData, 'project._id', props.rowData?.order?.project?._id);
    });

    const files = computed(() => (get(props.rowData, props.rowField.id, false)
      ? get(props.rowData, props.rowField.id, []).filter((file) => file.archived.value === false)
      : []));

    // filtered file list
    const filteredFileList = computed(() => {
      const list = files.value;
      return data.searchText === '' ? list : list.filter((el) => el?.name.toLowerCase().includes(data.searchText.toLowerCase()));
    });

    // file list isClosable
    const isClosable = (file) => {
      if (data.isSL) {
        return !file.master;
      }
      if (data.isBO && some(file.sources, { stage: 'planning' })) {
        return data.customStage === 'planning' || file.type === 'form';
      }
      return true;
    };

    // delete docs
    const removeDocs = (fileToDelete, cl) => {
      let todoIndex = 0;
      if (cl._id) {
        todoIndex = findIndex(props.rowData.simpleTodos, (todo) => cl._id === todo._id);
      } else {
        todoIndex = findIndex(
          props.rowData.simpleTodos,
          (todo) => cl.uuid === todo.uuid,
        );
      }
      let removingFile;
      if (todoIndex === -1) {
        removingFile = find(cl.files, (file) => {
          if (fileToDelete.uuid) return file.uuid === fileToDelete.uuid;
          return file._id === fileToDelete._id;
        });
      } else {
        removingFile = find(props.rowData.simpleTodos[todoIndex].files, (file) => {
          if (fileToDelete.uuid) return file.uuid === fileToDelete.uuid;
          return file._id === fileToDelete._id;
        });
      }
      if (!removingFile.uuid) {
        removingFile.archived.value = true;
      } else if (todoIndex === -1) {
        const index = findIndex(cl.files, {
          uuid: fileToDelete.uuid,
        });
        cl.files.splice(index, 1);
        // delete cl.files[index];
      } else {
        const index = findIndex(props.rowData.simpleTodos[todoIndex].files, {
          uuid: fileToDelete.uuid,
        });
        delete props.rowData.simpleTodos[todoIndex].files[index];
      }
    };

    const deleteFile = async (fileToDelete) => {
      const confirmParam = {
        title: 'DELETE FILE',
        message: 'Are you sure you want to delete this file? This cannot be undone.',
        okButton: 'Delete',
        cancelButton: 'Cancel',
        onConfirm: async () => {
          if (data.isBO || (props.rowData._accessor === 'items' && props.rowData.order instanceof BaseOrder)) {
            const removingFile = find(props.rowData.simpleFiles, (file) => {
              if (!fileToDelete.uuid) return file._id === fileToDelete._id;
              return file.uuid === fileToDelete.uuid;
            });

            removingFile.archived.value = true;

            if (removingFile.type === 'form') {
              props.rowData.order.deleteForm({ doc: removingFile, rowData: props.rowData });
            }
          }
          if (data.isSL) {
            if (props.rowData && props.rowData.delivery) {
              const removingFile = find(props.rowData.files, (file) => {
                if (!file.uuid) return file._id === fileToDelete._id;
                return file.uuid === fileToDelete.uuid;
              });
              if (removingFile.uuid) {
                // eslint-disable-next-line vue/no-mutating-props
                props.rowData.files = filter(props.rowData.files, findIndex(props.rowData.files, {
                  uuid: fileToDelete.uuid,
                }));
              } else {
                removingFile.archived.value = true;
              }
            }
          }
          if (data.isCL) {
            removeDocs(fileToDelete, props.rowData);
          }
          CardDirtyBus.emit('setCustomDirty');
        },
      };
      DialogProgrammatic.confirm(confirmParam);
    };

    const isDefPrivate = computed(() => data.isManager);
    const showForm = computed(() => ['ProdTemplates', 'MatTemplates', 'Prefabs', 'Materials', 'ProductionOrder', 'Sourcing'].includes(data.t) || data.isCL);
    // add documents
    const addDocs = (doc, cl) => {
      const file = pick(doc, ['name', 'type', 'visible', 'url', 'uuid', 'formData', 'copiedFrom']);
      file.archived = {
        value: false,
      };
      let todoIndex = 0;
      if (cl._id) {
        todoIndex = findIndex(props.rowData.simpleTodos, (todo) => cl._id === todo._id);
      } else {
        todoIndex = findIndex(props.rowData.simpleTodos, (todo) => cl.uuid === todo.uuid);
      }
      if (todoIndex === -1 && !cl.files) {
        cl.files = [];
        cl.files.push(file);
      } else if (todoIndex === -1 && cl.files) {
        cl.files.push(file);
      } else {
        if (isEmpty(props.rowData.simpleTodos[todoIndex].files)) {
          // eslint-disable-next-line vue/no-mutating-props
          props.rowData.simpleTodos[todoIndex].files = [];
        }
        // eslint-disable-next-line vue/no-mutating-props
        props.rowData.simpleTodos[todoIndex].files.push(file);
      }
    };

    const docsAdded = async (docs) => {
      for (const doc of docs) {
        const isItemTab = props.rowField?.for === 'Item' && ['material-edit-preparation', 'material-edit-sourcing', 'untitled', 'prefab-edit', 'material-edit-ordering'].includes(store.state.activeScreen);
        if (data.isBO || isItemTab) {
          const file = props.rowData.newFile();
          const docCopy = cloneDeep(doc);
          delete doc.formData;
          Object.assign(file, doc);

          if (doc.type === 'form' && props.rowData.order.addCheckListToForm) {
            props.rowData.order.addCheckListToForm(
              { doc: docCopy, rowData: props.rowData },
              store.state.userData,
            );
          }
        } else if (data.isSL) {
          const file = pick(doc, [
            'name',
            'type',
            'visible',
            'url',
            'uuid',
            'copiedFrom',
            'formData',
          ]);
          file.sources = [
            {
              type: 'shipping',
            },
          ];
          file.archived = {
            value: false,
          };
          if (props.rowData && props.rowData.delivery) {
            if (isEmpty(props.rowData.files)) {
              // eslint-disable-next-line vue/no-mutating-props
              props.rowData.files = [file];
            } else {
              // eslint-disable-next-line vue/no-mutating-props
              props.rowData.files.push(file);
            }
          }
        } else if (data.isCL) {
          addDocs(doc, props.rowData);
        }
      }
      CardDirtyBus.emit('setCustomDirty');
    };

    const closeDocsSlider = () => {
      emit('close');
    };

    return {
      ...toRefs(data),
      projectId,
      files,
      filteredFileList,
      isClosable,
      deleteFile,
      isDefPrivate,
      showForm,
      docsAdded,
      closeDocsSlider,
    };
  },
});
</script>
