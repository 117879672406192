<template>
  <div>
    <h4 class="is-size-3 has-text-black-bis mb-3">
      Notes
      <span class="is-pulled-right" v-tooltip="'Add New'">
        <i v-if="showAdd && !notesText.length" class="icon-addnew" @click="open"></i>
      </span>
    </h4>
  </div>
  <div class="file-lists">
    <div v-if="isEnabled">
      <div class="is-italic is-size-3 has-text-grey-darker line-height mb-2 has-text-left">
        {{ userName }}
        <span class="is-pulled-right buttons">
          <button class="button">
            <i v-if="isEnabled" class="icon-close" @click="cancel"></i>
          </button>
          <button class="button">
            <i v-if="isEnabled" class="icon-checkmark has-text-success" @click="save"></i>
          </button>
        </span>
      </div>
      <textarea class="textarea" rows="2" v-model="notesVal"></textarea>
    </div>
    <div v-if="!isEnabled && notesText.length" class="mf-file">
      <div class="is-size-3 line-height has-text-left text-overflow clamp-10">
        <button class="button is-pulled-right is-hidden" v-if="isSchedule ? scheduleEdit : true">
          <i class="icon-edit" @click="edit"></i>
        </button>
        {{ notesText }}
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent, reactive, toRefs, computed, onMounted,
} from 'vue';
import { useStore } from 'vuex';
import { trim } from 'lodash';

const MyComponent = defineComponent({
  name: 'mf-notes',
  props: {
    value: {
      type: String,
      default: '',
    },
    scheduleEdit: {
      type: Boolean,
      default: false,
    },
    isSchedule: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const data = reactive({
      isEnabled: false,
      userName: '',
      notesVal: '',
    });
    const cancel = () => {
      data.isEnabled = false;
    };
    const notesText = computed({
      get: () => trim(props.value),
      set: (val) => {
        emit('update:value', val);
      },
    });

    const save = () => {
      notesText.value = trim(data.notesVal);
      data.notesVal = '';
      data.isEnabled = false;
    };

    const open = () => {
      data.isEnabled = true;
    };

    const edit = () => {
      data.isEnabled = true;
      data.notesVal = notesText.value;
    };

    onMounted(async () => {
      const user = await store.getters.userPromise;
      data.userName = user.name;
    });

    const showAdd = computed(() => {
      if (props.isSchedule && !props.scheduleEdit) return props.scheduleEdit;
      return !data.isEnabled;
    });

    return {
      ...toRefs(data),
      cancel,
      open,
      edit,
      save,
      notesText,
      showAdd,
    };
  },
});
export default MyComponent;
</script>
