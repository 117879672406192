import validateLmv from '@/components/utils/lmv3dotValidation';

export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  isDetailed: true,
  stickeyHeaders: true,
  rearrangeCols: false,
  detailRowComponent: 'mf-detail-row',
  permissionModule: 'scm',
  gutterOpts: {
    buttons: [
      {
        event: 'columnChooser',
        icon: 'icon-tablecolumns',
        isLine: true,
        tooltip: 'Customise Columns',
      },
    ],
  },
  toolBar: {
    // name: for drop down label, label: for action button label
    bulkActions: () => {
      const opts = [{
        name: 'Change Dates', event: 'massUpdateDates', label: 'Change Dates',
      },
      {
        name: 'Print QR Labels',
        event: 'bulkQrPrint',
        label: 'Print QR',
        refreshOnCancel: true,
      }];
      return opts;
    },
    hideSearchBar: false, // by default let the search bar be available for the table
    buttons: () => [{
      event: 'excelExport',
      icon: 'icon-exportexcel',
      tooltip: 'Export to Excel',
    }],
  },
  fields: [
    {
      title: 'Material Orders',
      name: 'field-generic-input',
      sortField: 'name',
      prop: 'name',
      width: '400px',
      sticky: true,
      id: 'name',
      isClickable: (rowData) => rowData?.isDisabled,
      eventType: 'openCard',
      defaultVisible: true,
      alwaysVisible: true,
      searchField: false, // to give a search bar for the field
      searchable: true, // to place any custom field in the header of the column like for below
      bulkActionButtonField: true,
      inputProps: {
        type: 'text',
      },
    },
    {
      title: 'Description',
      name: 'field-generic-input',
      prop: 'financialId',
      sortField: 'financialId',
      width: '152px',
      id: 'financialId',
      isDisabled: false,
      defaultVisible: false,
    },
    {
      title: 'Kit ID',
      name: 'field-kit-id',
      prop: 'customId',
      width: '152px',
      id: 'kitId',
      sortField: 'customId',
      isDisabled: true,
      defaultVisible: true,
    },{     
      title: 'Activity',
      name: 'field-generic-input',
      prop: 'activity',
      sortField: 'activity',
      searchable: true,
      width: '152px',
      id: 'activity',
      isDisabled: false,
      defaultVisible: false,
      alwaysVisible: false,
    },
    {
      name: 'lmvIcon',
      width: '64px',
      title: '',
      id: 'lmvIcon',
      customHeader: true,
      customSlotHeader: true,
      defaultVisible: true,
      inputProps: {
        icon: 'icon-linked-model',
      },
    },
    {
      title: 'Project',
      name: 'field-generic-input',
      prop: 'project.name',
      sortField: 'project',
      searchable: true,
      filterText: 'Projects',
      width: '296px',
      id: 'project',
      isDisabled: true,
      defaultVisible: true,
    }, {
      title: 'Stage',
      name: 'field-stage',
      prop: 'stage',
      searchable: true,
      filterText: 'Stages',
      id: 'stage',
      width: '152px',
      defaultVisible: true,
      alwaysVisible: false,
      sortField: 'stage',
      cardProps: {
        cardType: '__t',
      },
    }, {
      title: 'Owner',
      name: 'field-user-select',
      prop: 'owner.user',
      width: '296px',
      sortField: 'effectiveOwner.user',
      id: 'owner',
      searchable: true,
      filterText: 'Owners',
      defaultVisible: true,
      alwaysVisible: false,
      cardProps: {
        companyId: 'created.by.company._id',
        projectId: 'project._id',
        isEditing: 'isEditing',
      },
    }, {
      name: 'company',
      id: 'companies',
      title: 'Company',
      width: '296px',
      show: true,
      sortField: 'name',
      searchable: true,
      filterText: 'Companies',
      defaultVisible: false,
      alwaysVisible: false,
      iPadVisible: true,
    }, {
      title: 'Vendor',
      name: 'field-vendor-select',
      prop: 'baseDelivery.vendor',
      sortField: 'baseDelivery.vendor',
      width: '296px',
      id: 'vendors',
      exportId: 'vendor',
      searchable: true,
      isDisabled: true,
      filterText: 'Vendors',
      defaultVisible: true,
      cardProps: {
        isEditing: 'isEditing',
      },
    }, {
      title: 'Location',
      name: 'field-location-select',
      prop: 'baseDelivery.location',
      sortField: 'baseDelivery.location',
      width: '296px',
      id: 'locations',
      exportId: 'location',
      defaultVisible: true,
      searchable: true,
      filterText: 'Locations',
      cardProps: {
        companyId: 'created.by.company._id',
        projectId: 'project._id',
        isEditing: 'isEditing',
      },
    },
    {
      title: 'Recipient',
      name: 'field-user-select',
      prop: 'baseDelivery.recipient',
      sortField: 'baseDelivery.recipient',
      width: '296px',
      defaultVisible: true,
      alwaysVisible: false,
      id: 'recipients',
      exportId: 'recipient',
      searchable: true,
      filterText: 'Recipients',
      disabled: true,
      cardProps: {
        companyId: 'created.by.company._id',
        projectId: 'project._id',
        isEditing: 'isEditing',
      },
      inputProps: {
        kind: 'baseDelivery.recipient',
      },
    },
    {
      title: 'Level',
      name: 'field-lvl-zone',
      isDisabled: false,
      sortField: 'baseDelivery.level',
      width: '296px',
      id: 'level',
      prop: 'baseDelivery.level',
      searchable: true,
      searchField: 'baseDelivery.level',
      searchPlaceHolder: 'Level',
      filterText: 'Level',
      inputProps: {
        options: 'levels',
      },
    },
    {
      title: 'Zone',
      name: 'field-lvl-zone',
      isDisabled: false,
      sortField: 'baseDelivery.zone',
      width: '296px',
      id: 'zone',
      prop: 'baseDelivery.zone',
      searchField: 'baseDelivery.zone',
      searchable: true,
      searchPlaceHolder: 'Zone',
      filterText: 'Zone',
      inputProps: {
        options: 'zones',
      },
    }, {
      title: 'Earliest Order By',
      name: 'field-date',
      defaultVisible: true,
      alwaysVisible: false,
      sortField: 'dates.orderBy',
      prop: 'orderBy',
      kind: 'dates',
      width: '152px',
      id: 'orderBy',
      searchable: true,
      dateFilter: 'orderBy',
      inputProps: {
        kind: 'orderBy',
        max: ['available', 'shipBy', 'deliver'],
        disableEdit: true,
      },
    },
    {
      title: 'Onsite',
      name: 'field-date',
      searchable: true,
      dateFilter: 'deliver',
      defaultVisible: true,
      alwaysVisible: false,
      sortField: 'deliver',
      prop: 'deliver',
      kind: 'dates',
      width: '152px',
      id: 'onSiteBy',
      inputProps: {
        kind: 'deliver',
        min: ['orderBy', 'available', 'shipBy'],
        disableEdit: false,
      },
    },
    {
      name: 'field-notes-icon',
      title: 'Notes',
      prop: 'notes',
      defaultVisible: true,
      width: '64px',
      id: 'notes',
    },
    {
      title: 'Actions',
      name: 'table-field-actions',
      id: 'action',
      hideFromChooser: true,
      alwaysVisible: true,
      width: '112px',
      sticky: true,
      permissionModule: 'scm',
      permissionKey: 'material-status',
      thAttrs: () => ({ class: 'action-sticky' }),
      tdClass: 'action-sticky',
      searchable: true,
      clearBtn: true,
      permissionModule: 'scm',
      inputProps: {
        showEdit: true,
        options(card) {
          const opts = [{
            label: 'History',
            event: 'activity',
            icon: 'fa fa-history',
          }];
          if (!['sourcing', 'preparation', 'mixed', 'rejected'].includes(card.stage)) {
            opts.push({
              icon: 'fa fa-qrcode',
              label: 'Print QR',
              event: 'printQr',
            });
          }
          if (validateLmv.validate3dots(card.items)) {
            opts.push({
              icon: 'icon-linked-model',
              label: 'View Model',
              event: 'lmv',
            });
          }
          return opts;
        },
      },
    },
  ],
};
