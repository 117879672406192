<template>
  <o-modal
      :active="isActive"
      :canCancel="false"
      class="modal-sm"
    >
    <o-loading :full-page="false"
      :active="isLoading"
      :can-cancel="true"></o-loading>
      <div class="modal-card">
        <header class="modal-card-head">
          <h4 class="modal-card-title">
            {{ rowData?.items[0]?.name || itemName }}
          </h4>
        </header>
        <section class="modal-card-body is-size-4 px-0 model-height" v-if="!dataLoaded"></section>
        <section class="modal-card-body is-size-4 px-0" v-else>
          <o-loading :full-page="false" :active="isLoading" :can-cancel="false"></o-loading>
          <div class="mb-4">
            <h5 class="is-size-5 has-text-black-bis is-italic pl-2 has-text-weight-semibold ">Item ID</h5>
            <h5 class="is-size-3 has-text-black-bis mb-2 pl-2">{{customId }}
           </h5>
            <h5 class="is-size-5 has-text-black-bis is-italic pl-2 has-text-weight-semibold">Project</h5>
            <h5 class="is-size-3 has-text-black-bis mb-2 pl-2">{{rowData?.project.name}} </h5>
          </div>

          <div class="qr-scan-body">
            <div class="status-bar">
              <h5 class="is-size-5 has-text-black-bis has-text-weight-bold is-uppercase pt-3 pl-2">Current Item Order/status</h5>
            </div>
              <div class="column has-text-black-bis">
                <div class="columns">
                  <div class="column is-2">
                    <div v-if="['s-m', 's', 'm'].includes(currentCard.shipType)" class="stage-color" v-stage-color="'#675322'">
                      Shipping
                    </div>
                     <stage-field v-else
                      :value="currentCard.stage"
                      >
                    </stage-field>
                    </div>
                  <div class="column  is-align-items-center is-flex line-height">
                    <generic-field 
                    :rowField="{name: 'field-generic-input', alwaysVisible: true, isClickable: true}"
                    :rowData="rowData"
                    :value="currentCard.name"
                    @cellClicked="openCard"
                    ></generic-field>
                  </div>
                </div>
                <div class="columns">
                <div class="column is-2 is-align-items-center is-flex">
                  <stage-field
                      :value="currentCard.status"
                      :isStatus="true"
                      :isStaticColor="currentCard.shipType ? false : true"
                      /></div>
                  <div class="column" v-for="(date, index) of getDate(currentCard)" :key="index">
                    <div class="is-size-5 has-text-black-bis">
                      <mf-date :rowData="date"  :input-props="{kind: Object.keys(date)[0]}" v-if="date[Object.keys(date)[0]]"
                          :is-edit="false" :label="date.label">
                        </mf-date>
                        <div class="has-text-black-bis is-size-3  line-height"  v-else>
                          <h5 class=" has-text-weight-semibold">{{ date.label }}</h5>
                          <h5 class="">MM/DD/YYYY </h5>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item-order-history-col  has-text-black-bis" v-show="previousCards?.length">
                <div class="item-order-header">
                  <h5 class="is-size-5 has-text-white has-text-weight-bold is-uppercase  pt-3  pl-2"> Item Order History ([{{ previousCards?.length }}])</h5>
                </div>
                <div class="history-body">
                  <div class="column border-inside is-align-items-center"
                    v-for="previousCard in previousCards" :key="previousCard._id">
                    <div class="columns">
                      <div class="column is-2">
                        <div v-if="previousCard.stage === 'zombie'" class="stage-color" v-stage-color="'#675322'">
                          Shipping
                        </div>
                        <stage-field v-else
                        :value="$_.isUndefined(previousCard.stage) && previousCard.status === 'zombie' ? 'shipping' : previousCard.stage"
                        >
                      </stage-field>
                      </div>
                      <div class="column is-align-items-center is-flex line-height">{{previousCard.name}}</div>
                    </div>
                    <div class="columns">
                      <div class="column is-2 is-align-items-center is-flex">
                        <stage-field
                        :value="previousCard.status"
                        :isStatus="true"
                        :isStaticColor="(previousCard.shipType || previousCard.status === 'zombie') ? false : true"
                        />
                      </div>
                      <div class="column" v-for="(date, index) of getDate(previousCard)" :key="index">
                    <div class="has-text-black-bis">
                      <mf-date :rowData="date"  :input-props="{kind: Object.keys(date)[0]}" v-if="date[Object.keys(date)[0]]"
                          :is-edit="false" :label="date.label">
                        </mf-date>
                        <div class="has-text-black-bis is-size-3  line-height"  v-else>
                          <h5 class=" has-text-weight-semibold">{{ date.label }}</h5>
                          <h5 class="">MM/DD/YYYY </h5>
                        </div>
                    </div>
                  </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="item-order-history-col  has-text-black-bis" v-show="planningCard?.length">
                <div class="item-order-header">
                  <h5 class="is-size-5 has-text-white has-text-weight-bold is-uppercase  pt-3  pl-2">Item Planning</h5>
                </div>
                <div class="history-body">
                  <div class="column border-inside is-align-items-center"
                    v-for="pCard in planningCard" :key="pCard.__id">
                    <div class="columns">
                      <div class="column is-2">
                        <stage-field
                        :value="pCard.stage"
                        >
                      </stage-field>
                      </div>
                      <div class="column is-align-items-center is-flex line-height">{{pCard.name}}</div>
                    </div>
                    <div class="columns">
                      <div class="column is-2 is-align-items-center is-flex">
                        <stage-field
                        :value="pCard.status"
                        :isStatus="true"
                        :isStaticColor="true"
                        >
                      </stage-field>
                      </div>
                      <div class="column" v-for="(date, index) of getDate(pCard)" :key="index">
                    <div class="is-size-5 has-text-black-bis">
                      <mf-date :rowData="date"  :input-props="{kind: Object.keys(date)[0]}"  v-if="date[Object.keys(date)[0]]"
                          :is-edit="false" :label="date.label">
                        </mf-date>
                        <div class="has-text-black-bis is-size-3  line-height" v-else>
                          <h5 class=" has-text-weight-semibold">{{ date.label }}</h5>
                          <h5 class="">MM/DD/YYYY </h5>
                        </div>
                    </div>
                  </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </section>
        <footer class="modal-card-foot is-justify-content-flex-end">
           <button
                class="button has-background-black-bis has-text-white"
                @click="close()"
              >
                Done
            </button>
        </footer>
      </div>
  </o-modal>
</template>

<script>
import { computed, onBeforeMount, onMounted, reactive, toRef, toRefs} from 'vue';
import { useStore } from 'vuex';
import _, { sample } from 'lodash';
import Qr from '@/models/Qr';
import { useRouter } from 'vue-router';
import StageField from '@/components/fields/StageField'
import SelectStatus from '@/components/fields/selectStatus.vue';
import MfDate from '@/components/abstract/MfDate.vue';
import GenericField from '@/components/fields/GenericField.vue';
import CardEditMixin from '@/components/mixins/CardEditMixin';

export default {
  name: 'ItemQRScan',
  components:  {
    StageField,
    SelectStatus,
    MfDate,
    GenericField,
  },

  props: [
  'isActive',
  'itemId',
  'cardId',
  'qrType',
  'name',
  'stage',
  'card',
  'customId',
  'rowData',
  'alwaysVisible',
  'isClickable',
  'rowField',
  'label',
  'itemName',
],
  setup(props, {emit}) {
    const store = useStore();
    const router = useRouter();
    const { rowField, rowData } = props;
    const { openWithUserPref } = CardEditMixin();
    const state = reactive({
      currentCard: {},
      planningCard: [],
      previousCards: [],
      isLoading: false,
      dataLoaded: false,
    })
    const getItemDate =[
   {
      id: 'coord-date',
      label: 'Coordinate By',
      kind: 'coord',
      stage: 'coordination'
    },{
      id: 'detailBy',
      label: 'Detail By',
      kind: 'detailBy',
      stage: 'detailing'
    }, {
      id: 'manufactureBy',
      label: 'Manufacture By',
      kind: 'manufactureBy',
      stage: 'manufacturing',
    }, {
      id: 'shipBy',
      label: 'Ship By',
      kind: 'deliveryStart',
      stage: 'shipping'
    }, {
      id: 'qaBy',
      label: 'QA By',
      kind: 'qaBy',
      stage: 'qaBy'
    }, {
      id: 'orderBy',
      label: 'Earliest Order By',
      kind: 'orderBy',
      stage: 'orderBy',
    }
   ];

   const isSameId = (ord) => {
    if (ord._id === state.currentCard._id) return true;
    return false;
   }

  const openCard = () => {
    const order = rowData?.order || rowData;
    if (['s-m', 's', 'm'].includes(order.shipType || state.currentCard?.shipType)) {
      router.push({
        name: 'shipping-edit',
        params: { projectId: order.project._id, cardId: state.currentCard._id },
      });
     } else if ((order.stage === 'detailing' && state.currentCard.stage === 'manufacturing') || 
     (!isSameId(order) && ['detailing', 'manufacturing'].includes(order.stage))) {
      router.push({
        name: 'manager-edit',
        params: { projectId: order.project._id, cardId: state.currentCard._id, stage: state.currentCard.stage },
      });
    } else {
      openWithUserPref(order)
    }
  }
  const getDateVal = (order) => {
    if (order.__t === 'Prefabs') {
      return _.find(getItemDate, (item) => item.kind === 'coord');
    } else if (order.__t === 'Sourcing') {
      return _.find(getItemDate, (item) => item.kind === 'orderBy');
    }
     return _.find(getItemDate, (item) => item.stage === order.stage) || _.find(getItemDate, (item) => item.stage === 'manufacturing');
  }
   const getDate = (order) => {
    const dates = [];
    if(order.__t) {
      const currentDate = getDateVal(order);
      const detailByVal = _.find(order.dates, { kind: currentDate.kind })?.value;
      const deliverByVal = _.find(order.dates, { kind: 'deliver' })?.value;
      const dateObj = { };
      dateObj[currentDate.kind] = detailByVal;
      dateObj.label = currentDate.label;
      dates.push(dateObj, {deliver: deliverByVal, label: 'Onsite'});
    }else {
      const shipBy = order?.delivery?.deliveryStart;
      const deliver = order?.delivery?.deliverBy;
      dates.push( {shipBy: shipBy, label: 'Ship By'}, {deliver: deliver, label: 'Onsite'});
    }
    return dates;
   }
   const close = () => {
    emit('close');
   }

  onBeforeMount(async () => {
      state.isLoading= true;
      state.dataLoaded = false;
      const itemData =  await Qr.getItemQRHistory({
        itemId:props.itemId,
        cardId:props.cardId,
        qrType:props.qrType});
      state.currentCard = itemData?.currentCard;
      state.planningCard = itemData?.planningCard;
      state.previousCards = itemData?.previousCards;
      state.isLoading=false;
      state.dataLoaded = true;
    })
    return ({
      ...toRefs(state),
      getDate,
      close,
      openCard,
      getDateVal,
    })
  }
}
</script>

<style scoped>
  .model-height {
    height: 587px !important;
  }
</style>