<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <h4 class="modal-card-title">
        {{ getTitle }}
      </h4>
      <div class="is-divider-vertical"></div>
      <i class="icon-close is-clickable" @click="cancel()"></i>
    </header>
    <section class="modal-card-body is-size-2 is-marginless">
      <h3 v-if="forShipping" class="mb-5 has-text-black-bis has-text-weight-bold is-size-3">
        {{ `Inventory Location: ${card.delivery.currentLocation.name}`}}
      </h3>
      <search-bar
        placeholder="Search"
        :shouldEmit="shouldEmit"
        @search="getSearchValue"
      ></search-bar>
      <div class="table-container">
        <mf-table
          :tableProps="tableProps"
          :loadData="loadData"
          :hideGutter="true"
          ref="itemTable"
        >
          <template v-slot:select-catalog="{ rowData: item }">
           <o-checkbox v-model="item.selected"
            @update:modelValue="toggleItem(item)">
           </o-checkbox>
          </template>
          <template v-slot:qty-needed="{ rowData : item }">
            <qty-input v-if="item.selected"
              v-model.number="item.qtyNeeded"
              :value="item.qtyNeeded" @update:value="itemQtyChanged($event, item)"
              :roundTo="4" :max="maxQtyAllowed(item)"
            >
            </qty-input>
          </template>
        </mf-table>
      </div>
    </section>
    <footer class="modal-card-foot is-justify-content-flex-end">
      <button class="button is-outlined" true type="button" @click="cancel()">
        Cancel
      </button>
      <button
        class="button has-background-black-bis"
        type="button"
        :disabled="isDisabled"
        @click.once="importItems()"
      >
        Select
      </button>
    </footer>
    <o-loading
      :full-page="false"
      :active="isLoading"
      :can-cancel="true"
    ></o-loading>
  </div>
</template>
<script>
import {
  defineComponent, reactive, toRefs, ref, computed,
} from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import {
  isEmpty, isUndefined, each, filter, get, set, some, find,
  forEach, clamp, cloneDeep, pick, map, concat,
} from 'lodash';
import moment from 'moment';
import SearchBar from '@/components/SearchBar.vue';
import QtyInput from '@/components/fields/QtyInput.vue';
import MfTable from '@/components/table-fields/MfTable.vue';
import tableDefinition from '@/components/table-cols/selectFromInvCols';
import MaterialManager from '@/models/MaterialManager';
import Catalogs from '@/models/Catalogs';
import ShippingLabel from '@/models/ShippingLabel';
import Helper from '@/models/Helper';
import { BaseOrder } from '@/models/BaseOrder';
import Order from '@/models/Orders';
import SupplyChain from '@/models/SupplyChain';
import Project from '@/models/Projects';
import MaterialTemplates from '@/models/MaterialTemplates';

export default defineComponent({
  name: 'AddFromInventory',
  components: {
    SearchBar,
    MfTable,
    QtyInput,
  },
  props: {
    card: Object,
    isSelectedInventory: Boolean,
    forShipping: {
      type: Boolean,
      default: false,
    },
    shouldEmit: false,
  },
  setup(props, { emit }) {
    const tableProps = tableDefinition;
    const toast = useToast();
    const store = useStore();
    const itemTable = ref(null);
    const state = reactive({
      project: {},
      card: props.card,
      commonStockProject: {},
      selectedItems: {},
      searchText: '',
      isLoading: false,
      allUsers: [],
    });
    const getTitle = computed(() => {
      if (props.forShipping) {
        return 'Select Materials From Inventory';
      }
      const title = props.isSelectedInventory ? 'Inventory' : 'Catalog';
      return `Select From ${title}`;
    });
    const isDisabled = computed(() => !some(Object.values(state.selectedItems), 'value'));
    const loadData = async (commonParams) => {
      let data = {};
      if (!props.forShipping) {
        [state.project, state.commonStockProject] = await Promise.all([
          Project.get(state.card.project._id),
          Project.getCommonStockProject(),
        ]);
        const { projectSettings } = state.project || [];
        const params = {
          inStock: 'hideOutofStock',
          requireCatId: false,
          getOnlyRoot: state.card._customStage === 'manufacturing',
          locs: [get(projectSettings[0], 'projectInventoryLocation._id', '')],
          projectIds: [state.commonStockProject._id, state.card.project._id],
          ...commonParams,
          search: store.state.queryParams.searchText || state.searchText,
          limit: 1000,
        };
        if (props.isSelectedInventory) {
          data = await SupplyChain.inventory({ params, ...params }, false, true);
          if (!['manufacturing', 'detailing', 'coordination'].includes(state.card.stage)) {
            data.data = filter(data.data || [], (item) => item.available > 0 && item.catId);
          } else data.data = filter(data.data || [], (item) => item.available > 0);
        } else {
          params.type = 'Parts';
          params.projectId = params.projectIds;
          data = await Catalogs.getAssemblyParts(params);
          if (!isEmpty(data)) {
            for (const item of data.data) {
              if (!isEmpty(item.inventoryInfo)) {
                item.available = item.inventoryInfo[0].available;
              } else {
                item.available = 0;
              }
            }
          }
        }
      } else {
        const params = {
          inStock: 'hideOutofStock',
          locs: [props.card.delivery.currentLocation._id],
          projectIds: [props.card.project._id],
          search: store.state.queryParams.searchText,
          ...commonParams,
        };
        data = await SupplyChain.inventory({ params, ...params }, false, true);
        data.data = data.data.filter((item) => {
          if (item.available > 0) return item;
        });
      }
      if (isEmpty(data)) {
        data.data = [];
      }
      return data;
    };
    const cancel = () => {
      emit('close');
    };
    const getSearchValue = (searchText) => {
      state.searchText = searchText;
      itemTable.value.refreshTable();
    };
    // eslint-disable-next-line consistent-return
    const importItems = async () => {
      if (props.forShipping) {
        emit('addItem', state.selectedItems);
      } else {
        let shippingOrder = {};
        try {
          state.allUsers = await Project.linkedProjectUsers([state.project._id]);
          if (some(state.selectedItems, (val) => val.value
          && (isUndefined(val.item.qtyNeeded) || val.item.qtyNeeded < 0.01))) {
            return toast.error('Item quantity should be greater than 0.');
          }
          // Before adding materials to the card updating the order with unsaved data
          const { catQtyMaps } = state.card;
          each(state.selectedItems, (val) => {
            const predicate = {};
            if (val.value === true) {
              if (isEmpty(val.item.catId)) {
                predicate._id = get(val.item, 'uid', val.item._id);
              } else {
                predicate.catId = val.item.catId;
              }
              let catQtyMap = find(catQtyMaps, predicate);
              if (!isEmpty(catQtyMap)) {
                if (state.card.purpose === 'kit') {
                  catQtyMap.qtyToShip += val.item.qtyNeeded;
                } else catQtyMap.qtyToConsume += val.item.qtyNeeded;
              } else {
                if (val.item._parentPurpose === 'assembly' || state.card.purpose === 'kit') {
                  catQtyMap = {
                    ...predicate,
                    qtyToConsume: 0,
                    qtyToShip: val.item.qtyNeeded,
                  };
                } else {
                  catQtyMap = {
                    ...predicate,
                    qtyToConsume: val.item.qtyNeeded,
                    qtyToShip: 0,
                  };
                }
                catQtyMaps.push(catQtyMap);
              }
            }
          });
          state.card.items = _.filter(state.card.items, (item) => !item.archived.value);
          state.card = await state.card.save();
          forEach(state.selectedItems, (val) => {
            if (['manufacturing'].includes(state.card._customStage) && val.item.qtyNeeded > val.item.available) {
              val.item.qtyNeeded = clamp(Math.floor(val.item.qtyNeeded), 0.01, val.item.available);
            }
          });
          state.isLoading = true;
          let mm = {};
          if (!isEmpty(state.card.materials) && !props.isSelectedInventory) {
            mm = await MaterialManager.getWishList({
              orderId: state.card._id,
              projectIds: [state.card.project._id],
            });
          } else if (!isEmpty(state.card.fromInvOrderId) && props.isSelectedInventory) {
            mm = await MaterialManager.getOne({
              cardId: state.card.fromInvOrderId[0],
              projectId: state.card.project._id,
            });
          }
          if (['manufacturing'].includes(state.card._customStage)) {
            shippingOrder = new ShippingLabel({
              name: `Reserve-${Helper.getWishListName(state.card, store.state.userData)}`,
              purpose: 'general',
              isInternal: true,
              project: state.card.project,
              reserveFor: state.card,
              items: [],
              externalEmails: [],
              shipType: 's-m',
              forBOM: false,
              delivery: {
                currentLocation: get(state.project.projectSettings[0], 'projectInventoryLocation._id', ''),
                currentProject: state.card.project,
                deliveryLocation: state.card.manager.location,
                deliveryProject: state.card.project,
                deliveryStart: moment().hours(12),
                deliverBy: moment().hours(12),
                owner: get(state.card, 'manager.owner.user', ''),
                recipient: get(state.card, 'manager.owner.user', ''),
                notifyUsers: [],
              },
            });
          }
          each(state.selectedItems, (val) => {
            if (val.value === true) {
              if (['manufacturing'].includes(state.card._customStage)) {
                val.item.cardId = state.card._id;
                shippingOrder.addItemFromInventory(val.item, val.item.qtyNeeded);
              }
              if (!isEmpty(val.item.catId)) {
                const newItem = pick(val.item, ['name', 'catId', 'purchase']);
                if (isEmpty(mm)) {
                  const card = {
                    _id: '',
                    name: Helper.getWishListName(state.card, store.state.userData),
                    __t: 'Materials',
                    stage: 'preparation',
                    project: state.card.project,
                    customId: state.card.customId,
                  };
                  mm = new BaseOrder(card);
                  const company = {
                    _id: store.state.userData.company,
                    name: store.state.userData.companyName,
                  };
                  mm.owner.user = state.card.owner.user || store.state.userData;
                  mm.owner.company = state.card.owner.company || company;
                  mm.addOrUpdateDate('deliver', state.card.simpleDates.deliver.value);
                  mm.createdFrom.type = ['manufacturing'].includes(state.card._customStage) ? 'inventory' : 'wishList';
                  mm.createdFrom.deviceId = 'web';
                  const defaults = state.project.getDefaults(mm, 'preparation');
                  if (!isEmpty(defaults.newOwner)) {
                    const owner = find(state.allUsers, (usr) => usr._id === defaults.newOwner._id);
                    mm.owner.user._id = owner._id;
                    mm.owner.user.name = owner.name;
                    mm.owner.company._id = owner.company;
                    mm.owner.company.name = owner.companyName;
                  }
                  if (!isEmpty(defaults.newLocation)) {
                    mm.baseDelivery.location = defaults.newLocation;
                  } else if (get(state.project.projectSettings[0], 'projectInventoryLocation', '')) {
                    mm.baseDelivery.location = get(state.project.projectSettings[0], 'projectInventoryLocation', '');
                  }
                }
                const existingItem = find(
                  mm.items || [],
                  (i) => !isEmpty(i.catId) && i.catId.toString() === newItem.catId.toString(),
                );
                if (!isEmpty(existingItem)) {
                  existingItem.quantity += Number(val.item.qtyNeeded);
                } else {
                  newItem.quantity = Number(val.item.qtyNeeded);
                  mm.addItem({ stage: mm.stage, ...newItem });
                }
              }
            }
          });
          if (shippingOrder.items) {
            for (const item of shippingOrder.items) {
              item.cardId = state.card._id;
            }
            await shippingOrder.save();
            await shippingOrder.receive();
          }
          if (!['manufacturing'].includes(state.card._customStage)) {
            let defaultTemplate = {};
            defaultTemplate = await MaterialTemplates.getDefaultTemplate({
              skipCheck: true,
            });
            if (!isEmpty(defaultTemplate)) {
              const clonedCard = cloneDeep(mm);
              mm = mm.fillBasicTemplateData(defaultTemplate);
              mm.owner = defaultTemplate.owner;
              mm.name = clonedCard.name;
              mm.applyTemplate = true;
              if (isEmpty(get(mm, 'baseDelivery.location', {}))) {
                mm.baseDelivery.location = get(clonedCard, 'baseDelivery.location', {});
              }
              /*  Since in create call sends date as empty array, and template
              is applied in create call, without sending dates,
              */
              mm = new BaseOrder(await MaterialManager.create(mm));
            }
            if (!props.isSelectedInventory) {
              mm = await mm.save();
              let ids = map(state.card.materials, '_id');
              ids = concat(ids, mm._id);
              await Order.addMaterials(state.card, ids);
            }
          }
          if (!isEmpty(mm) && props.isSelectedInventory) {
            if (isEmpty(state.card.fromInvOrderId)) {
              mm.notFromRequisition = true;
              mm = await mm.save();
              state.card.fromInvOrderId.push(mm._id);
              await state.card.save();
            } else {
              mm = await mm.save();
            }
          }
        } catch (e) {
          const errorMsg = e?.data?.msg || 'Error Adding Materials. Please contact ManufactOn support'
          toast.error(errorMsg);
        }
        state.isLoading = false;
        emit('refresh-table');
      }
      emit('close');
    };
    const toggleItem = (item) => {
      const itemId = item.catId;
      let val;
      if (itemId === '' || isEmpty(state.selectedItems[itemId])) {
        item.qtyNeeded = 1;
        val = { value: true, item };
      } else {
        val = { value: !state.selectedItems[itemId].value, item };
      }
      state.selectedItems[itemId] = val;
    };
    const maxQtyAllowed = (item) => {
      return ((props.isSelectedInventory || props.forShipping) ? item.available : 9999)
    };
    const itemQtyChanged = ((newQty, item) => {
      state.selectedItems[item.catId].item.qtyNeeded = newQty || 1;
    });
    return {
      ...toRefs(state),
      tableProps,
      loadData,
      cancel,
      itemTable,
      getSearchValue,
      importItems,
      toggleItem,
      itemQtyChanged,
      isDisabled,
      maxQtyAllowed,
      getTitle,
    };
  },
});
</script>
<style scoped>
::v-deep(.o-table tbody) {
  min-height: 385px !important;
  max-height: 385px !important;
}
</style>
