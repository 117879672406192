<template>
  <div :class="positionClass">
    <label class="has-text-black-bis is-italic is-size-3 line-height" v-if="label">
      {{ label }}
    </label>
    <div class="control is-flex-grow-1" v-if="isEditable && !disableInput(rowData)">
        <span v-if="!$_.isEmpty(selectOptions)">
           <mf-multi-select
            v-model="inputValue"
            :placeholder="placeHolder"
            :options="selectOptions"
            :searchable="true"
            :allow-empty="true"
          />
        </span>
         <span v-else>
           <input
              class="input generic-input"
              :type="type"
              v-model="inputValue"
              :placeholder="placeHolder"
              @change="onChange"
            />
        </span>
    </div>
    <div class="is-flex-grow-1" v-else>
    <span>
      {{inputValue}}
    </span>
    </div>
  </div>
</template>
<script>
import { computed, reactive, toRefs } from 'vue';
import { get, isUndefined, isFunction } from 'lodash';
import { useStore } from 'vuex';
import MfMultiSelect from '@/components/abstract/MfMultiSelect.vue';
import ProductionManagerMixin from '@/components/mixins/ProductionManagerMixin';

export default {
  name: 'LevelOrZoneField',
  components: {
    'mf-multi-select': MfMultiSelect,
  },
  props: [
    'label',
    'value',
    'textValue',
    'type',
    'isEditing',
    'labelPosition',
    'placeHolder',
    'isDisabled',
    'isClickable',
    'eventType',
    'rowField',
    'rowData',
    'isInput',
    'options',
    'projectId',
    'cardId',
    'listView',
  ],
  setup(props, context) {
    const store = useStore();
    const { disableInput } = ProductionManagerMixin();
    const state = reactive({
      positionClass: 'field',
    });
    if (props.labelPosition) {
      switch (props.labelPosition) {
        case 'is-left':
          state.positionClass = 'field is-horizontal';
          break;
        case 'is-right':
          state.positionClass = 'field is-horizontal has-text-right is-pulled-right';
          break;
        case 'is-centered':
          state.positionClass = 'field is-horizontal has-text-centered';
          break;
        default:
          state.positionClass = 'field';
          break;
      }
    }

    const isEditable = computed(() => {
      if (props.rowField?.inputProps && isFunction(props.rowField?.inputProps?.isDisabled)) {
        return !props.rowField?.inputProps?.isDisabled(props.rowData);
      }
      return props.rowField?.inputProps?.isEditing || props.isEditing
      || false;
    });

    const inputValue = computed({
      get: () => {
        const rowValue = get(props, 'value');
        if (rowValue && Array.isArray(rowValue)) return rowValue.toString();
        return rowValue;
      },
      set: (val) => {
        context.emit('update:value', val);
      },
    });

    const selectOptions = computed(() => {
      let { projectId } = props;
      if (isUndefined(projectId)) {
        projectId = props.rowData.project._id;
      }
      const project = store.getters.findProject({ _id: projectId }) || { levels: [], zones: [] };
      return get(project, props.options, []);
    });

    return {
      ...toRefs(state),
      inputValue,
      selectOptions,
      isEditable,
      disableInput,
    };
  },
};
</script>
