<template>
  <o-loading :full-page="Boolean(false)" :active="showLoading" :can-cancel="false">
  </o-loading>
  <mf-table
    ref="itemLocsTable"
    :tableProps="tableProps"
    :loadData="loadData"
    tableName="itemLocs"
    :hideGutter="true"
    :api-mode="false"
    @cell-clicked="onCellClicked"
    :key="data.length"
    tableClass="inventory-location"
  >
    <template v-slot:name="{ rowData, rowIndex }" class="">
      <div v-if="rowData.key === 'showMore'">
        <!-- show more button -->
        <button
          @click="getCurrentChildLocs(rowData, rowIndex, !rowData.isChildRowEnabled)"
        >
          {{ getName(rowData) }}
        </button>
      </div>
      <div v-else>
        <div class="child-inventory-loc" :style="{width: `${(rowData.level - 1) * 16}px`}"></div>
        <div class="is-flex is-align-items-center">
            <i :class="[rowData.children.length ? 'icon-disclosearrow' : '',
            rowData.isChildRowEnabled ? '' : 'fa-rotate-270']"
          @click="getCurrentChildLocs(rowData, rowIndex, !rowData.isChildRowEnabled)"
          :style="{marginLeft: `${(rowData.level - 1) * 16}px`}"></i>
          <span>{{ getName(rowData) }}</span>
        </div>
      </div>
    </template>
    <template v-slot:inventory-notes="{ rowData: item }">
      <div v-if="item.isEditing">
        <input
          v-model.trim="item.inventoryNotes"
          maxlength="32"
          class="input pl-1"
        />
      </div>
      <span v-else-if="!item.isChildRowEnabled && $_.isEmpty(item.children)"
        v-tooltip="item.inventoryNotes" class="text-overflow text-clamp">
        {{ item.inventoryNotes }}
      </span>
    </template>
    <template v-slot:available="{ rowData: item }">
      <div v-if="item.isEditing && isDisabledOnPermission(item) && canEdit(item)">
        <qty-input
          v-model="item.newAvailable"
          :value="item.newAvailable"
          :min="0"
          :max="item.available + 9999"
          :roundTo="4"
          :allowNegative="true"
        ></qty-input>
        <p class="" v-if="item.available !== item.newAvailable">
          <i
            v-if="item.newAvailable > item.available"
            class="icon-disclosearrow"
          ></i>
          <i
            v-if="item.newAvailable < item.available"
            class="fa-rotate-270 icon-disclosearrow"
          ></i>
          ({{ item.available > item.newAvailable ? "Used" : "Added" }}
          {{ Math.abs(item.newAvailable - item.available).toFixed(2) }})
        </p>
        <p class="" v-if="item.available === item.newAvailable">
          (Adjust {{ item.available }})
        </p>
      </div>
      <div v-else class="has-text-right">
        <span v-if="!item.isChildRowEnabled">
          <i v-if="showLowStock(item)"
            class="icon-alert has-text-danger"></i>
          {{ item.available }}
        </span>
      </div>
    </template>
    <template v-slot:consumed="{ rowData }">
      <div v-if="!rowData.isChildRowEnabled" class="has-text-right">
        {{ rowData?.actual?.consumed }}
      </div>
    </template>
    <template v-slot:reserved="{ rowData }">
      <div v-if="!rowData.isChildRowEnabled" class="has-text-right">
        {{ rowData.reserved }}
      </div>
    </template>
    <template v-slot:delivery="{ rowData }">
      <div v-if="!rowData.isChildRowEnabled" class="has-text-right">
        {{ rowData.delivery }}
      </div>
    </template>
    <template v-slot:minStock="{ rowData, rowField, vuetable }">
      <span v-if="!rowData.isChildRowEnabled">
        <qty-input
          v-model="rowData.minQuantity"
          :value="rowData.minQuantity"
          :min="0"
          max="9999"
          :roundTo="4"
          :isDisabled="!rowData.isEditing"
          @update:value="updateStockQty('minQuantity',$event, rowData)"
        ></qty-input>
      </span>
    </template>
    <template v-slot:maxStock="{ rowData, rowField, vuetable }">
      <span v-if="!rowData.isChildRowEnabled">
        <qty-input
          :value="rowData.maxQuantity"
          :min="0"
          max="9999"
          :roundTo="4"
          :isDisabled="!rowData.isEditing"
          @update:value="updateStockQty('maxQuantity',$event, rowData)"
        ></qty-input>
      </span>
    </template>
    <template v-slot:measure="{ rowData }">
      <div v-if="!rowData.isChildRowEnabled && $_.isEmpty(rowData.children)">
        {{ rowData.measure }}
      </div>
    </template>
    <template v-slot:measureUnits="{ rowData }">
      <div v-if="!rowData.isChildRowEnabled && $_.isEmpty(rowData.children)">
        {{ rowData.measureUnits }}
      </div>
    </template>
  </mf-table>
</template>
<script>
import {
  defineComponent,
  computed,
  onMounted,
  reactive,
  toRefs,
  ref,
  nextTick,
} from 'vue';
import { useStore } from 'vuex';
import uuid from 'uuid/v4';
import {
  set,
  has,
  get,
  forEach,
  findIndex,
  filter,
  find,
  isEmpty,
} from 'lodash';
import { useToast } from 'vue-toastification';
// import Locations from '@/models/Locations';
import SupplyChain from '@/models/SupplyChain';
import QtyInput from '@/components/fields/QtyInput.vue';
import GenericField from '@/components/fields/GenericField.vue';
import MfTable from '@/components/table-fields/MfTable.vue';
import getNestedCols from '@/components/table-cols/inventoryDetailsCols';

export default defineComponent({
  props: {
    rowData: Object,
  },
  components: {
    MfTable,
    QtyInput,
    GenericField,
  },
  setup({ rowData, rowField }) {
    const toast = useToast();
    const store = useStore();
    const itemLocsTable = ref(null);
    const state = reactive({
      data: [],
      showLoading: false,
      requiredCheck: [
        { module: 'inventory', key: 'quantity' },
        { module: 'material-manager', key: 'data' },
        { module: 'material-manager', key: 'move' },
        { module: 'shipping', key: 'data' },
        { module: 'shipping', key: 'receive' },
      ],
      selectedInStock: store.getters.selectedInStock,
    });
    const tableProps = computed(() => ({
      showHeader: false,
      fields: getNestedCols(store.getters.activeScreenCols, true),
    }));

    const hideChildLocs = (item) => {
      const removingLocs = [];
      state.data.forEach((d) => {
        const locParents = (d.key !== 'showMore') ? d.rootLoc.allParents : d.allParents;
        if (!isEmpty(locParents) && locParents.includes(item._id)) {
          removingLocs.push(d);
        }
      });
      if (removingLocs.length) {
        removingLocs.forEach((r) => {
          state.data.splice(findIndex(state.data, { _id: r._id }), 1);
        });
      }
    };

    const isHavingMoreLocs = async (row, totalLength, limit, currChildsLength, page) => {
      let retObj = {};
      if (
        totalLength > limit
        && row.renderedLength + currChildsLength !== totalLength
      ) {
        retObj = {
          key: 'showMore',
          parentId: row.parentId ? row.parentId : row._id,
          _id: uuid(),
          renderedLength: row.renderedLength
            ? row.renderedLength + currChildsLength
            : currChildsLength,
          page: page + 1,
          level: row.level,
          catId: row.catId,
          allParents: [
            row.parentId ? row.parentId : row._id,
            ...row.allParents,
          ],
          rootLoc: {
            nestedLocation: true,
            name: 'Show More',
            level: row.level,
            children: row.rootLoc ? row.rootLoc.children : row.children,
          },
        };
      }
      return retObj;
    };

    const getCurrentItemLocs = async (item, page, limit) => {
      const restQryObj = { catId: item.uid };
      // below condition to get the selected company location in filter for
      // level 1 location or for first time when flyout is active instead of location children
      let selectedLocations = store.state.queryParams.selectedCompanyLocations;
      if (item.level === 1 || isEmpty(item.children)) {
        selectedLocations = selectedLocations.filter((loc) => loc.level === 1).map((loc) => loc._id);
      }
      const locations = (item.level === 1 || isEmpty(item.children))
        ? selectedLocations : item.rootLoc.children;
      const params = {
        baseCardType: ['Part', 'Assembly'],
        projectId: store.getters.selectedIdsForKey('filteredProjects'),
        locs: locations,
        ...restQryObj,
        search: store.state.queryParams.searchText,
        inStock: state.selectedInStock,
        locationView: true,
        limit,
        page,
      };
      const res = await SupplyChain.inventory(params, false, true);
      const data = [];
      forEach(res.data, (i) => {
        i.level += 1;
        data.push({
          name: i.name,
          inventoryNotes: i.inventoryNotes[0],
          _id: i.rootLoc._id,
          children: get(i, 'rootLoc.children', []),
          level: i.level,
          available: i.available,
          newAvailable: i.available,
          reserved: i.reserved,
          delivery: i.delivery,
          rootLoc: i.rootLoc,
          allParents: get(i, 'rootLoc.allParents', []),
          catId: i.catId,
          uid: i._id.uid,
          minQuantity: i.minQuantity,
          maxQuantity: i.maxQuantity,
          project: i.project[0],
          itemIds: i.catId ? null : i._id.uid,
          actual: i.actual,
          measure: i.measure,
          measureUnits: i.measureUnits,
        });
      });
      const availableVal = get(item, 'actual.available', 0);
      if (item.level > 1 && (store.state.queryParams.inStock.value === 'showAll'
        || availableVal) && !has(item, '__t')) {
        data.unshift({
          name: item.name,
          inventoryNotes: item.inventoryNotes,
          _id: item.rootLoc._id + 1,
          children: [],
          level: item.level + 1,
          available: availableVal,
          newAvailable: availableVal,
          reserved: get(item, 'actual.reserved', 0),
          delivery: get(item, 'actual.delivery', 0),
          rootLoc: {
            ...item.rootLoc,
            name: `${item.rootLoc.name} - General Storage`,
            allParents: [item._id, ...item.allParents],
            children: [],
          },
          isRoot: item.level === 1,
          catId: item.catId,
          maxQuantity: get(item, 'actual.maxQuantity', 0),
          minQuantity: get(item, 'actual.minQuantity', 0),
          project: item.project,
          itemIds: item.itemIds,
          isGeneralStorage: true,
          uid: item.uid,
          measure: item.measure,
          measureUnits: item.measureUnits,
        });
      }
      if (isEmpty(data)) { toast.warning('No Quantity is present for this item in sub locations '); }
      return { data, total: res.total };
    };

    const getChild = async (item, page, limit) => {
      state.showLoading = true;
      let currentChilds = [];
      let totalLength = 0;
      ({
        data: currentChilds,
        total: totalLength,
      } = await getCurrentItemLocs(item, page, limit));
      state.showLoading = false;
      currentChilds = currentChilds.data || currentChilds;
      return { currentChilds, totalLength };
    };
    const maxStockAllowed = 9999;
    const updateStockQty = (updateType, updatedValue, row) => {
      updatedValue = parseInt(updatedValue, 0);
      const quantity = (updatedValue > maxStockAllowed) ? maxStockAllowed : updatedValue;
      if (updateType === 'minQuantity') {
        row.minQuantity = quantity;
        return;
      }
      if (updateType === 'maxQuantity') {
        row.maxQuantity = quantity;
      }
    };

    const getCurrentChildLocs = async (item, index, isEnabled) => {
      const limit = 1000;
      const page = item.key === 'showMore' ? item.page : 1;
      set(item, 'isChildRowEnabled', isEnabled);
      let currentChilds = [];
      let totalLength = 0;
      if (
        item.isChildRowEnabled
        || item.refresh
        || item.key === 'showMore'
      ) {
        state.showLoading = true;
        try {
          ({ currentChilds, totalLength } = await getChild(
            item,
            page,
            limit,
          ));
        } catch (e) {
          console.log(e);
        } finally {
          state.showLoading = false;
        }
      } else {
        hideChildLocs(item);
      }
      if (currentChilds.length) {
        if (!item.renderedLength) item.renderedLength = 0;
        const showMoreObj = isHavingMoreLocs(
          item,
          totalLength,
          limit,
          find(currentChilds, { isGeneralStorage: true }) ? currentChilds.length - 1
            : currentChilds.length,
          page,
        );
        if (!isEmpty(showMoreObj)) currentChilds.push(showMoreObj);
        state.data.splice(index + 1, 0, ...currentChilds);
        if (item.key === 'showMore') state.data.splice(index, 1);
      }
    };

    onMounted(async () => {
      await getCurrentChildLocs(rowData, 0, true);
    });

    const loadData = () => state.data;

    const getName = (data) => (data.rootLoc.nestedLocation
      ? data.rootLoc.name
      : `${data.rootLoc.name} [${data.project.name}]`);

    const showLowStock = (item) => item.available < item.minQuantity;

    const canEdit = (item) => {
      if (get(item, 'rootLoc.nestedLocation', false)) { return true; }
      if ((item.newAvailable - item.available) > 0) {
        const projectDetails = store.getters.findProject({
          _id: item.project._id,
        });
        // if (projectDetails.isGI) return false;
        const proUser = projectDetails.projectUsers.find(
          (usr) => usr.user._id === store.state.userData._id,
        );
        if (!projectDetails.isGI && !proUser) return false;
      }
      return true;
    };

    const isDisabledOnPermission = (row) => {
      let res = true;
      state.requiredCheck.forEach((pm) => {
        if (res) {
          res = store.getters.getPermissionVal(
            {
              permissionModule: pm.module,
              rowData: row,
            },
            pm.key === 'data' ? 'save' : pm.key,
          );
        }
      });
      return res;
    };

    const updateTable = (currItem, val, key) => {
      let rowsToUpdate = [];
      // find all it's parents to update.
      const filteredVal = filter(state.data, (row) => currItem.rootLoc.allParents
              && currItem.rootLoc.allParents.includes(row.rootLoc._id)
              && !row.isGeneralStorage);
      rowsToUpdate = [currItem, ...filteredVal];
      if (['available', 'consumed', 'minQuantity', 'maxQuantity'].includes(key)) {
        set(rowData, key, (rowData[key] + val));
      }
      // update current and parent's qty.
      rowsToUpdate.forEach((item, i) => {
        if (key === 'available' || i > 0) { item[key] += val; }
      });
    };

    const saveRow = async (item) => {
      try {
        const index = findIndex(state.data, { _id: item._id });
        const incDecVal = Number((item.newAvailable - item.available).toFixed(2));
        if (incDecVal > 0 || incDecVal < 0) {
          await SupplyChain.adjustItemQty(item);
        }
        // uid is item's catId
        item._id = { catId: item.uid };
        await SupplyChain.itemUpdate(item);
        set(state.data[index], 'isEditing', false);
        if (item.newAvailable === 0 && state.selectedInStock === 'hideOutofStock') {
          state.data.splice(index, 1);
        }
        toast.success('Item saved');
        updateTable(item, item.minQuantity - item._beforeEdit.minQuantity, 'minQuantity');
        updateTable(item, item.maxQuantity - item._beforeEdit.maxQuantity, 'maxQuantity');
        // updating consumed value for item table after decrementing
        if (incDecVal < 0) {
          updateTable(item, Math.abs(incDecVal), 'consumed');
        }
        if (Math.abs(incDecVal)) {
          updateTable(item, incDecVal, 'available');
        }
      } catch (e) {
        console.log(e);
        toast.error('Update Failed');
      }
    };

    const onCellClicked = async (e) => {
      state.showLoading = true;
      if (e.type === 'save') {
        await saveRow(e.data);
      }
      if (e.type === 'edit') {
        state.showLoading = false;
        return;
      }
      set(state, 'data', []);
      await nextTick();
      await getCurrentChildLocs(rowData, 0, true);
      // eslint-disable-next-line no-unused-expressions
      itemLocsTable?.value?.refreshTable();
      state.showLoading = false;
    };
    return {
      ...toRefs(state),
      tableProps,
      loadData,
      getName,
      getCurrentChildLocs,
      showLowStock,
      isDisabledOnPermission,
      canEdit,
      onCellClicked,
      updateStockQty,
    };
  },
});
</script>
<style scoped>
  li {
    list-style: none;
  }
  ::v-deep(.o-table__detail td) {
    padding-right: 0;
  }
</style>
