function extraTableParams() {
  return {
    defaultSortField: 'name',
    defaultSortOrder: 'asc',
    isDetailed: false,
    rearrangeCols: false,
    toolBar: false,
    isCardView: true,
  };
}

const tableOpts = {
  toolBar: {
    hideSearchBar: true, // by default let the search bar be available for the table
    buttons: [
      {
        event: 'addVendor',
        icon: 'icon-addnew',
        isLine: true,
        tooltip: 'Add Vendor',
        acl: {
          permissionModule: 'prefabs',
          type: 'edit',
        },
        isDisabled: (routeParams, props) => !props?.rowData?.isEditing,
      },
      {
        event: 'saveVendor',
        icon: 'icon-checkmark',
        isLine: true,
        tooltip: 'Save',
        acl: {
          permissionModule: 'prefabs',
          type: 'edit',
        },
        isDisabled: (routeParams, props) => !props?.rowData?.isEditing,
      },
    ],
  },
};

export default {
  'vendor-details': {
    ...extraTableParams(),
    ...tableOpts,
    fields: [
      {
        name: 'field-generic-input',
        width: '112px',
        title: 'Pref Vendor',
        id: 'prefVendor',
        type: 'checkbox',
        prop: 'isPreferred',
        inputProps: {
          disableEdit: false,
        },
      },
      {
        name: 'vendorName',
        width: '410px',
        title: 'Name',
        id: 'vendorName',
        prop: 'name',
      },
      {
        name: 'defVendor',
        width: '80px',
        title: 'Default Vendor',
        id: 'defVendor',
      },
      {
        name: 'code',
        width: '112px',
        title: 'Vendor SKU/Part #',
        id: 'code',
        tdClass: 'has-text-right',
      },
      {
        name: 'desc',
        width: '400px',
        title: 'Description',
        id: 'desc',
      },
      {
        name: 'unitCost',
        width: '112px',
        title: 'Item Cost',
        id: 'unitCost',
        fieldClass: 'has-text-right',
      },
      {
        name: 'leadTime',
        width: '112px',
        title: 'Lead Time (Days)',
        id: 'leadTime',
        fieldClass: 'has-text-right',
      },
      {
        title: '',
        name: 'table-field-actions',
        id: 'action',
        hideFromChooser: true,
        alwaysVisible: false,
        width: '112px',
        searchable: false,
        clearBtn: true,
        showAllFilters: false,
        // thAttrs: () => ({ class: 'action-sticky' }),
        // tdClass: 'action-sticky',
        inputProps: {
          showDelete: true,
          showEdit: false,
          disableDelete: false,
          // disableDelete: (row) => {
          //   console.log('row...', row);
          // },
        },
      },
    ],
  },
};
