<template>
  <div
    class="
      card-edit
    "
    v-if="cardLoaded"
    :key="refreshCount"
  >
    <card-edit-subheader
      :type="type"
      :module="card.__t"
      :stage="stage"
      :card="card"
      :status="card?.status"
    >
    </card-edit-subheader>
    <div class="edit-body">
      <div class="has-background-grey-lighter card-basic column p-0">
        <div class="is-flex hero-header is-size-3 columns is-marginless mb-3">
          <div class="column is-6 is-flex is-align-items-flex-end">
            <i class="has-text-white image is-32x32 icon-logisticsmanager"
              :class="getDynamicClasses">
            </i>
            <h3 class="is-size-2 line-height has-text-weight-bold">
              {{ getTransferRequestType }} Request
            </h3>
          </div>
          <div class="column is-6 is-flex is-align-items-flex-end">
            <span class="has-text-weight-semibold mr-2 is-italic">
              {{ getDestOrSrcLabel }}
            </span>
            <div v-if="isDestSelectable" class="is-flex is-align-item-flex-end is-align-items-center is-clickable">
              <span>*</span>
              <span v-if="isEmpty(getDestOrSrcLoc)" @click="selectLocation">
                (Select)
              </span>
              <span v-else>
                {{ getDestOrSrcLoc }}
              </span>
              <button class="button px-0">
                <i class="icon-more" @click="selectLocation"></i>
              </button>
            </div>
            <div v-else>
              {{ getDestOrSrcLoc }}
            </div>
          </div>
        </div>
        <div class="columns mb-0">
          <div class="column is-3">
            <div class="field">
              <label class="label has-text-black-bis is-italic is-size-3"
                >Request ID</label
              >
              <h3 class="label is-size-3 has-text-black-bis has-text-weight-normal">
                {{ card.taskId }}
              </h3>
            </div>
            <div class="field">
              <label class="label has-text-black-bis is-italic is-size-3">Owner</label>
              <div class="control">
                <mf-multi-select
                  v-model="card.assignee.user"
                  :options="allUsers"
                  :multiple="false"
                  :searchable="true"
                  label="name"
                  track-by="_id"
                  :allow-empty="false"
                >
                </mf-multi-select>
              </div>
            </div>
          </div>
          <div class="column is-offset-3">
            <div class="field">
              <field-stage
                v-show="true"
                label="Status"
                :isEditing="false"
                :shipType="card.type"
                :value="card.status"
                :isStatus="true"
                :isStaticColor="true"
              ></field-stage>
            </div>
            <div class="field">
              <label class="label has-text-black-bis is-italic is-size-3">
                Percent Complete
              </label>
              <div class="transfer-percentage is-size-3 has-text-weight-bold line-height
                is-flex is-justify-content-center">
                {{ getPercentageCompleted }}%
              </div>
            </div>
          </div>
          <div class="column is-2 pb-0">
            <div class="field">
              <mf-date
                :item="card"
                :inputProps="{ kind: 'dueDate' }"
                :label="dueDateText"
                isEdit="true"
              >
              </mf-date>
            </div>
            <div class="field">
              <mf-date
                :item="card"
                :inputProps="{ kind: 'created.at' }"
                label="Created"
              >
              </mf-date>
            </div>
            <div class="field">
              <label class="label has-text-black-bis is-italic is-size-3"
                >Days active</label
              >
            </div>
          </div>
        </div>
        <div class="column px-0 pt-0">
          <div
            class="field is-flex is-align-items-center is-justify-content-space-between"
          >
            <o-checkbox class="p-0" v-model="showCompletedItems">
              <span class="is-size-3 has-text-weight-normal">
                {{ getShowButtonText() }}
              </span>
            </o-checkbox>
            <label class="is-size-3 has-text-weight-bold">
              {{ getDaysActive() }}
            </label>
          </div>
        </div>
        <!-- Item section -->
        <div class="transfer-items">
          <div class="columns transfer-request-columns">
            <div
              class="column is-4 transfer-items-card is-clickable"
              v-for="item in filteredItems"
              :key="item._id"
              @click="openActionModal(item)"
            >
              <item-card :item="item" :type="card.type"> </item-card>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer class="card-footer">
      <div class="buttons" v-if="!card.isDirty">
        <button
          class="button has-background-black-bis has-text-white"
          @click="transferAll"
          :disabled="!isTakeAllActive"
        >
          {{ getPickButtonText() }}
        </button>
        <button
          class="button has-background-black-bis has-text-white"
          @click="getItemsToExecute"
          :disabled="!isDeliverActive"
        >
          {{ getDeliverButtonText() }}
        </button>
      </div>
      <div class="buttons" v-else>
        <button
          class="button has-background-black-bis has-text-white"
          @click="reloadPage()"
        >
          Discard Changes
        </button>
        <button
          class="button has-background-black-bis has-text-white"
          @click="saveTask"
        >
          Save Changes
        </button>
      </div>
    </footer>
    <o-loading :full-page="true" :active="isLoading" :can-cancel="false"></o-loading>
  </div>
</template>

<script>
import {
  reactive, toRefs, onBeforeMount, computed,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ModalProgrammatic } from '@oruga-ui/oruga-next';
import { useToast } from 'vue-toastification';
import CardEditSubHeader from '@/components/card-edit/CardEditSubheader.vue';
import Tasks from '@/models/Task';
import ItemCard from '@/views/card-edit/item-card.vue';
import Users from '@/models/Users';
import moment from 'moment';
import MfDate from '@/components/abstract/MfDate.vue';
import ItemInTransferRequest from '@/components/modals/ItemInTransferRequest.vue';
import {
  capitalize, filter, isEmpty, get, divide, some, isUndefined, pick, multiply, round, set,
} from 'lodash';
import AddLocation from '@/components/modals/AddLocation.vue';
import CardEditMixin from '@/components/mixins/CardEditMixin';
import { CardDirtyBus } from '@/utils/CardDirtyBus';

export default {
  name: 'transfer-request-edit',
  components: {
    'card-edit-subheader': CardEditSubHeader,
    'item-card': ItemCard,
    MfDate,
  },
  props: {
    requestId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const toast = useToast();
    const { onInitDirty } = CardEditMixin();
    const state = reactive({
      transferReq: {},
      card: {},
      type: '',
      cardLoaded: false,
      allUsers: [],
      showCompletedItems: false,
      isLoading: false,
      refreshCount: 0,
    });

    const getUsersParams = {
      showUser: 'active',
      limit: 1000,
    };

    const DateConfig = {
      dates: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-date',
              prop: 'dueDate',
              label: 'Due Date',
              hideDate: false,
            },
            {
              name: 'field-date',
              prop: 'created',
              label: 'Created',
              hideDate: false,
            },
          ],
        },
      ],
    };

    const formattedDate = (val) => (val ? moment(val).format('MM/DD/YYYY') : '');

    const getDynamicClasses = computed(() => {
      const routeMeta = router.currentRoute.value.meta;
      let classes = '';
      if (routeMeta.activeViewColor) {
        classes = `${routeMeta.activeViewColor} `;
      }
      if (routeMeta.cardEditIcon) {
        classes += `icon-${routeMeta.cardEditIcon}`;
      }
      return classes;
    });

    const getDestOrSrcLabel = computed(() => (state.card.type === 'pick' ? 'Destination' : 'Source'));

    const getDestOrSrcLoc = computed(() => (state.card.type === 'pick'
      ? get(state.card, 'destinationLocation.name', '')
      : get(state.card, 'sourceLocation.name', '')));

    const dueDateText = computed(() => (moment(new Date()).isAfter(moment(state.card.dueDate))
      ? 'Due Date (Overdue)'
      : 'Due Date'));

    const getDaysActive = () => moment(new Date()).diff(moment(state.card.created.at), 'days');

    const getShowButtonText = () => (state.card.type === 'pick'
      ? 'Show delivered items'
      : 'Show finished items');

    const getTransferRequestType = computed(() => capitalize(state.card?.type));

    const getPickButtonText = () => (state.card.type === 'pick' ? 'Auto-Take Items' : 'Auto-Stock Items');

    const getDeliverButtonText = () => (state.card.type === 'pick' ? 'Deliver Taken Items' : 'Finished Stocking');

    const fromInventory = () => get(router, 'history.current.query.isEditMode', false);

    const isDestSelectable = computed(() => !(state.card.createdFor || state.card.type === 'stock'));

    const setTaskDestination = async (val) => {
      state.card.destinationLocation = pick(val, ['id', '_id', 'name', 'fullAddress']);
      await Tasks.update(state.card._id, pick(state.card, ['destinationLocation']));
    };

    const isTakeAllActive = computed(() => {
      const { items } = state.card;
      if (items && items.length && items.every((i) => i.locations.length === 1)) {
        if (state.card.type === 'stock') {
          return items.some(
            ({ quantityMap }) => quantityMap.toStock > quantityMap.stocked,
          );
        }
        return !items.every((item) => {
          const {
            totalQtyAvailable, delivered, locations, quantityMap,
          } = item;
          if (parseFloat(quantityMap.needed) > parseFloat(totalQtyAvailable)) {
            return (
              delivered
              && parseFloat(locations[0].moveQuantity)
                === parseFloat(totalQtyAvailable)
            );
          }
          return (
            parseFloat(quantityMap.needed) === parseFloat(quantityMap.taken)
          );
        });
      }
      return false;
    });

    const isDeliverActive = computed(() => {
      const { items } = state.card;
      if (state.card.type === 'stock') {
        return items && items.length && items.some((i) => i.finished);
      }
      return items && items.length && items.some((i) => i.delivered);
    });

    const loadData = async () => {
      const { requestId } = props;
      const [data, itemLocations] = await Promise.all([
        Tasks.getOne({ taskId: requestId }),
        Tasks.getItemLocation(requestId),
      ]);
      itemLocations.data.forEach((i) => {
        if (data.type === 'stock') {
          i.finished = false; // setting flag to diff stocked items
          i.locations.forEach((loc) => {
            loc.finished = false;
          });
        } else {
          i.delivered = false; // setting flag to diff picked items
          i.locations.forEach((loc) => {
            loc.delivered = false;
          });
        }
      });
      state.transferReq = {
        ...data,
        items: itemLocations.data,
        name: data.taskId,
      };
      state.card = state.transferReq;
      onInitDirty(state.card);
      return { data: [state.transferReq], total: 1 };
    };

    const openActionModal = (item) => {
      const isFinished = item.quantityMap.stocked === item.quantityMap.toStock;
      ModalProgrammatic.open({
        component: ItemInTransferRequest,
        props: {
          item,
          card: state.card,
          onlyNestedLocs: true,
          isFinished,
        },
        rootClass: 'modal-sm',
        canCancel: false,
        events: {
          close: async () => { },
        },
      });
    };

    const selectLocation = () => {
      ModalProgrammatic.open({
        component: AddLocation,
        props: {
          for: 'transferRequest',
          onlyNestedLocs: true,
        },
        trapFocus: true,
        canCancel: false,
        events: {
          'select-location': async (loc) => {
            setTaskDestination(loc);
            CardDirtyBus.emit('setCustomDirty');
          },
        },
        rootClass: 'modal-sm',
      });
    };

    const execute = async (data, items) => {
      state.isLoading = true;
      try {
        const params = {};
        params.items = items;
        let result;
        await Tasks.update(state.card._id, pick(state.card, ['taskId', 'dueDate', 'assignee', 'type', 'createdFor', 'items']));
        if (state.card.type === 'pick') {
          params.id = data._id;
          result = (await Tasks.executePick(params)).data;
        } else {
          params.taskId = data._id;
          result = (await Tasks.executeStock(params)).data;
        }
        if (result.status === 'complete') {
          toast.success('Material Transfer complete');
        }
        await loadData();
      } catch (error) {
        console.error(error);
        let errMsg = 'Server Error: Please contact Manufacton Support';
        if (
          get(error, 'data.message')
          === 'Error: Item quantity to stock not available'
        ) {
          errMsg = 'Item quantity to stock not available';
        }
        if (getDestOrSrcLoc.value === '') {
          errMsg = 'Please select destination location';
        } else if (error.message) errMsg = error.message;
        toast.error(`${errMsg}`);
      }
      state.isLoading = false;
    };

    const getItemsToExecute = async () => {
      const items = [];
      let itemsPicked = [];
      if (state.card.type === 'pick') {
        itemsPicked = filter(state.card.items, (item) => item.delivered === true);
      } else {
        itemsPicked = filter(state.card.items, (item) => item.finished === true);
      }
      for (const item of itemsPicked) {
        let locations = [];
        if (state.card.type === 'pick') {
          locations = filter(item.locations, (loc) => loc.delivered === true);
        } else {
          locations = filter(item.locations, (loc) => loc.finished === true);
        }
        for (const loc of locations) {
          const pickedItem = {};
          pickedItem.catId = loc.catId;
          if (state.card.type === 'pick') {
            pickedItem.itemId = loc.itemId;
            pickedItem.moveQuantity = loc.moveQuantity;
            pickedItem.locationId = loc.location._id;
          } else {
            pickedItem.itemId = item.itemId;
            pickedItem.qtyToStock = loc.qtyToStock;
            pickedItem.destLocation = loc.location._id;
          }
          items.push(pickedItem);
        }
      }
      await execute(state.card, items);
    };

    const transferAll = () => {
      state.isLoading = true;
      const { type, items } = state.transferReq;
      if (isEmpty(items) || items.some((i) => i.locations.length !== 1)) {
        toast.warning('Items are present in multiple locations. Please select manually.');
        return null;
      }
      for (const item of items) {
        const [location] = item.locations;
        const { quantityMap } = item;
        if (type === 'stock') {
          item.finished = true;
          location.finished = true;
          location.qtyToConsume = parseFloat(quantityMap.toStock) - parseFloat(quantityMap.stocked);
          item.quantityMap.stocked += location.qtyToConsume;
          if (!location.qtyToStock) location.qtyToStock = 0;
          location.qtyToStock += location.qtyToConsume;
          location.qtyAvailable -= location.qtyToConsume;
        } else {
          item.delivered = true;
          location.delivered = true;
          if (
            parseFloat(quantityMap.needed) > parseFloat(location.qtyAvailable)
          ) {
            if (!location.qtyToConsume) location.qtyToConsume = 0;
            item.quantityMap.taken = 0;
            item.quantityMap.taken += location.qtyAvailable;
            location.qtyToConsume = location.qtyAvailable;
          } else {
            const toBeTaken = parseFloat(quantityMap.needed) - parseFloat(quantityMap.taken);
            if (!location.qtyToConsume) location.qtyToConsume = 0;
            location.qtyToConsume += toBeTaken;
            item.quantityMap.taken += toBeTaken;
          }
          location.moveQuantity = location.qtyToConsume;
        }
      }
      state.isLoading = false;
      return null;
    };

    const filteredItems = computed(() => {
      if (state.showCompletedItems) return state.card.items;
      if (state.card.type === 'pick') {
        return state.card.items.filter(
          (item) => item.quantityMap.needed !== item.quantityMap.taken,
        );
      }
      return state.card.items.filter(
        (item) => item.quantityMap.toStock !== item.quantityMap.stocked,
      );
    });

    const getPercentageCompleted = computed(() => {
      let completedCount = 0;
      if (state.card.type === 'pick') {
        completedCount = state.card.items.filter(
          (item) => item.quantityMap.needed === item.quantityMap.taken,
        ).length;
      } else {
        completedCount = state.card.items.filter(
          (item) => item.quantityMap.toStock === item.quantityMap.stocked,
        ).length;
      }
      return round(multiply(divide(completedCount, state.card.items.length), 100));
    });

    const saveTask = async (stay = false) => {
      state.isLoading = true;
      try {
        const task = state.card;
        if (fromInventory) {
          if (some(task.items, (item) => item.quantityMap.needed === null) && task.type === 'pick') {
            toast.error('Please enter quantity needed for all items');
            return false;
          }
        }
        if (task.type === 'pick' && isUndefined(task.destinationLocation)) {
          toast.error('Please enter destination location');
          return false;
        }
        const pickParams = ['taskId', 'dueDate', 'assignee', 'type', 'createdFor'];
        if (!task.createdFor && task.type === 'pick') {
          pickParams.push('destinationLocation');
          pickParams.push('items');
        } else if (task.type === 'stock') {
          pickParams.push('items');
        }
        const taskMeta = pick(task, pickParams);
        await Tasks.update(task._id, taskMeta);
        if (task.type === 'pick' && task.items.length && task.items.some((i) => i.delivered)) await getItemsToExecute();
        // if (!stay) router.go(-1);
        await loadData();
        toast.success('Changes saved.');
      } catch (error) {
        console.log(error);
      } finally {
        state.isLoading = false;
      }
    };

    onBeforeMount(async () => {
      state.type = store.state.activeScreen;
      await loadData();
      state.allUsers = (await Users.allUsers(getUsersParams)).data;
      state.card.items.forEach((item) => {
        if (item.quantityMap.needed === null) {
          set(item, 'neededEmpty', true);
        }
      });
      state.cardLoaded = true;
    });
    const reloadPage = async () => {
      try {
        state.isLoading = true;
        await loadData();
        state.refreshCount++;
      } catch (e) {
        console.log(e);
      } finally {
        state.isLoading = false;
      }
    };

    return {
      ...toRefs(state),
      getDynamicClasses,
      DateConfig,
      formattedDate,
      openActionModal,
      getDestOrSrcLabel,
      getDestOrSrcLoc,
      getDaysActive,
      getShowButtonText,
      getTransferRequestType,
      getPickButtonText,
      getDeliverButtonText,
      getItemsToExecute,
      transferAll,
      filteredItems,
      getPercentageCompleted,
      dueDateText,
      isTakeAllActive,
      isDeliverActive,
      selectLocation,
      isDestSelectable,
      isEmpty,
      saveTask,
      reloadPage,
    };
  },
};
</script>

<style scoped>
.column.is-2 {
  border: 0 !important;
}
</style>
