<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-menu">
      <div class="navbar-end">
        <div class="navbar-item">
          <button
            class="button has-background-black-bis" :disabled="!hasPermissionToScan()"
            v-tooltip="{ content: 'Scan QR Code', placement: 'bottom' }"
            @click="setUrl"
          >
            <i class="icon-qrcodescan"></i>
          </button>
          <div class="is-divider-vertical"></div>
          <figure class="image is-48x48">
          </figure>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { defineComponent, onBeforeMount, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import _ from 'lodash';

export default defineComponent({
  name: 'MainHeader',
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const setUrl = () => {
      if (!route.path.startsWith('/qrView')) {
        store.dispatch('qrScan/setPrevUrl', route.path);
        router.push({
          path: '/webqr',
        });
      }
    };
    onBeforeMount(async () => {
      await store.getters.userPromise;
      store.state.userData;
    });
    const usercompanyModules = computed(() => {
      return store.state?.userData?.allowedModules?.companyModules
    });
    const hasPermissionToScan = () => {
      const { userData } = store.state;
      if (userData && !_.isEmpty(userData.resolvedPermissionGroups)) {
        return usercompanyModules.value.includes('general:qr_scan');
      }
      return true;
    }
    return {
      setUrl,
      usercompanyModules,
      hasPermissionToScan,
    };
  },

});
</script>

<style scoped>
.is-48x48 {
  width: 55px !important;
}
</style>
